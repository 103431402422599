import {ActivatedRouteSnapshot, provideRouter, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {inject} from '@angular/core';
import {bootstrapApplication} from '@angular/platform-browser';
import { IOutdoorPlaceReadWithCounters, IPlaceRead } from '../models/place';
import { IGetResponse, IGetResponsePaginated } from '../models/get-response';
import { PlaceService } from '../services/place.service';

export const indoorPlaceResolver: ResolveFn<IGetResponse<IPlaceRead>> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PlaceService).getIndoorById({place_id: route.paramMap.get('id')!});
  };

export const outdoorPlaceResolver: ResolveFn<IGetResponse<IPlaceRead>> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PlaceService).getOutdoorById({place_id: route.paramMap.get('id')!});
  };

export const outdoorPlaceListResolver: ResolveFn<IGetResponsePaginated<IOutdoorPlaceReadWithCounters>> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PlaceService).getOutdoorList({page: 1, size: 100});
  };
