import { IGetResponse } from '../models/get-response';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { AnalyticsService } from '../services/analytics.service';
import { IAnalyticsRead } from '../models/analytics';



export const analyticsDashboardResolver: ResolveFn<IGetResponse<IAnalyticsRead>> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AnalyticsService).getAnalytics({time_range: 'Last 24 hours'});
};
