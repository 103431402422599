import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {IGetResponse} from '../models/get-response';
import {TokenService} from './token.service';
import { IAnalyticsRead, ILostTagRead } from '../models/analytics';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService extends BaseService {
  constructor(http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  static readonly GetLostTagsTrackerPath = '/v1/analytics/lost-tags-tracker';
  static readonly GetAnalyticsPath = '/v1/analytics';

  getLostTagsTracker(params: {q?: string; lost_time?: number; time_range?: string; }, context?: HttpContext
  ): Observable<IGetResponse<ILostTagRead>> {
    const rb = new RequestBuilder(this.rootUrl, AnalyticsService.GetLostTagsTrackerPath, 'get');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.query('q', params.q, {});
      rb.query('lost_time', params.lost_time, {});
      rb.query('time_range', params.time_range, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<ILostTagRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<ILostTagRead>>) => r.body as IGetResponse<ILostTagRead>)
    );
  }

  getAnalytics(params: {time_range?: string; }, context?: HttpContext
  ): Observable<IGetResponse<IAnalyticsRead>> {
    const rb = new RequestBuilder(this.rootUrl, AnalyticsService.GetAnalyticsPath, 'get');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.query('time_range', params.time_range, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IAnalyticsRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IAnalyticsRead>>) => r.body as IGetResponse<IAnalyticsRead>)
    );
  }
}
